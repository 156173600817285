import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { getOpenOrder } from "../features/authSlice";
import logo from "../logo.png";
import "../landing.css";
//import { getOpenOrder } from "../features/authSlice";

const Homepage = () => {
  const [despatchNumber, setDespatchNumber] = useState("");
  const [despatchPostcode, setDespatchPostcode] = useState("");
  const [despatchAC, setDespatchAC] = useState("");
  const [despatchName, setDespatchName] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [msg, setMsg] = useState("");
  const { user, isError, isSuccess, isLoading, message, order } = useSelector(
    (state) => state.auth
  );
  const { no, pc } = useParams();

  useEffect(() => {
    // Set input fields based on URL parameters
    if (no) setDespatchNumber(no);
    if (pc) setDespatchPostcode(pc);
  }, [no, pc]);

  /*useEffect(() => {
    if ( isSuccess) {
      navigate("/dashboard");
    }
    dispatch();
  }, [ isSuccess, dispatch, navigate]);
*/

  useEffect(() => {
    if (order) {
      navigate(`/orderdetails/${order.id}`);
    }
  }, [order, navigate]);

  const checkOrder = async (e) => {
    e.preventDefault();
    try {
      /*const response = await axios.post("delchecker/checkorders", {
        orderRef: despatchNumber,
        // "ac":despatchAC,
        postcode: despatchPostcode,
      });
      */

      dispatch(
        getOpenOrder({ despatchNumber, despatchPostcode, despatchName })
      );
      /*if (order) {
        navigate(`/orderdetails/${order.id}`);
        // navigate(`/orderdetails/${response.data.id}`);
      }*/
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <section className="hero is-info is-fullheight">
      <div className="hero-head">
        <div className="container">
          <div className="column is-6 is-offset-3">
            <div className="box">
              <img src={logo} width="100%" height="235" alt="logo" />
            </div>
          </div>
        </div>
      </div>

      <div className="hero-body">
        <div className="container has-text-centered">
          <div className="column is-6 is-offset-3">
            <h1 className="title">Confirm your delivery</h1>

            <div className="box">
              <div className="field is-grouped">
                <div className="column ">
                  <form onSubmit={checkOrder} className="box">
                    {isError && <p className="has-text-centered">{message}</p>}

                    <div className="field">
                      <label className="label">Advice Note Number</label>
                      <div className="control">
                        <input
                          type="text"
                          className="input"
                          value={despatchNumber}
                          onChange={(e) => setDespatchNumber(e.target.value)}
                          placeholder="As displayed in the top right"
                        />
                      </div>
                    </div>
                    {/*  <div className="field">
                  <label className="label">A/C No</label>
                  <div className="control">
                    <input
                      type="text"
                      className="input"
                      value={despatchAC}
                      onChange={(e) => setDespatchAC(e.target.value)}
                      placeholder="Displayed in the delivery address box"
                    />
                  </div>
                </div>
  */}
                    <div className="field">
                      <label className="label">Delivery Postcode</label>
                      <div className="control">
                        <input
                          type="text"
                          className="input"
                          value={despatchPostcode}
                          onChange={(e) => setDespatchPostcode(e.target.value)}
                          placeholder="Displayed in the delivery address box"
                        />
                      </div>
                    </div>

                    <div className="field">
                      <label className="label">Enter Name</label>
                      <div className="control">
                        <input
                          type="text"
                          className="input"
                          value={despatchName}
                          onChange={(e) => setDespatchName(e.target.value)}
                          placeholder="Enter your name"
                        />
                      </div>
                    </div>
                    <div className="field mt-5">
                      <p>{msg}</p>
                      <button
                        type="submit"
                        className="button is-success is-fullwidth"
                      >
                        {isLoading ? "Loading..." : "Complete Delivery"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Homepage;
