import React, { useState, useEffect } from "react";
import axios from "axios";
import error from "../error.png";
import good from "../good.png";
import check from "../check.png";

const ProductItemComplete = ({ product }) => {
  const renderSwitch = (e) => {
    switch (e) {
      case "damaged":
        return "Damaged";
      case "missing":
        return "Missing";
      case "worngItem":
        return "Incorrect Item";
      default:
        return "";
    }
  };

  return (
    <>
      <tr>
        <td>{product.partCode}</td>
        <td>{product.description}</td>
        <td>{product.quantityDespatched}</td>
        <td>
          <img
            src={
              product.checked === "unchecked" || product.checked === "0"
                ? check
                : product.checked === "checked"
                ? good
                : error
            }
            height="40"
            width="40"
            alt="car"
          />
        </td>
      </tr>
      {product.checked === "error" && (
        <>
          <tr>
            <td colSpan={4}>
              <div className="field is-horizontal">
                <div className="select">{renderSwitch(product.lineError)}</div>
                <div className="field-label">
                  <label className="label">
                    How many are {product.lineError}?
                  </label>
                </div>
                {product.quantityMissing}
              </div>
            </td>
          </tr>
        </>
      )}
    </>
  );
};

export default ProductItemComplete;
