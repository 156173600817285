import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./components/Login";
import Homepage from "./components/Homepage";
import Users from "./pages/Users";
import Products from "./pages/Products";
import Orders from "./pages/Orders";
import AddUser from "./pages/AddUser";
import EditUser from "./pages/EditUser";
import AddProduct from "./pages/AddProduct";
import EditProduct from "./pages/EditProduct";
import AddOrder from "./pages/AddOrder";
import EditOrder from "./pages/EditOrder";
import CheckOrder from "./pages/CheckOrder";
import OrderScreen from "./pages/OrderScreen";
import ViewOrder from "./pages/ViewOrder";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />

          <Route path="/products" element={<Products />} />
          <Route path="/products/add" element={<AddProduct />} />
          <Route path="/products/edit/:id" element={<EditProduct />} />
          <Route path="/orders" element={<Orders />} />

          <Route path="/orders/add" element={<AddOrder />} />
          <Route path="/orders/edit/:id" element={<EditOrder />} />

          <Route path="/checkorder/:id" element={<CheckOrder />} />

          {/*  Open Pages*/}
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Homepage />} />
          <Route path="/:no/:pc" element={<Homepage />} />
          <Route path="/orderdetails/:id" element={<OrderScreen />} />
          {/*  Admin Pages */}
          <Route path="/users" element={<Users />} />
          <Route path="/users/add" element={<AddUser />} />
          <Route path="/users/edit/:id" element={<EditUser />} />

          {/*  User Pages*/}
          <Route path="/order/:id" element={<ViewOrder />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
