import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import ProductItem from "./ProductItem";
import ProductItemComplete from "./ProductItemComplete";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const OpenOrders = () => {
  const [order1, setOrder] = useState(null);
  const [products, setProducts] = useState([]);
  const [orderStatus, setOrderStatus] = useState("Opened");
  const { id } = useParams();
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const { user, order } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (user) {
      navigate(`/order/${id}`);
    }
  }, [user, id, navigate]);

  useEffect(() => {
    if (!order) {
      navigate("/");
    }
  }, [order, navigate]);

  const formatDate = (dateString) => {
    return moment(dateString).format("DD/MM/YYYY");
  };

  // Usage

  /*
  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);
*/
  /* useEffect(() => {
    console.log(products); // This will log the updated value
  }, [products]);*/

  useEffect(() => {
    const fetchOrder = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`/delchecker/checkorder/${id}`);
        setOrder(response.data);
        //setProducts(response.data.products);
        setProducts(response.data.products.map((product) => ({ ...product })));
      } catch (error) {
        // Improved error handling
        console.error("Error fetching order data:", error);
        setMsg(error.response ? error.response.data.msg : "An error occurred");
      }
      setIsLoading(false);
    };

    fetchOrder();
  }, [id]);

  const handleProductError = (productId, LineError) => {
    setProducts(
      products.map((product) =>
        product.id === productId
          ? { ...product, lineError: LineError }
          : product
      )
    );
  };
  const handleProductQtyMissing = (productId, quantityMissing) => {
    setProducts(
      products.map((product) =>
        product.id === productId ? { ...product, quantityMissing } : product
      )
    );
  };
  const handleProductStatus = (productId, checked) => {
    setProducts(
      products.map((product) =>
        product.id === productId ? { ...product, checked } : product
      )
    );
  };

  const updateProductStatus = (
    productId,
    checked,
    lineError,
    quantityMissing
  ) => {
    setProducts((currentProducts) => {
      const updatedProducts = currentProducts.map((product) =>
        product.id === productId
          ? { ...product, checked, lineError, quantityMissing }
          : product
      );

      // Check if any product is set as error
      const hasError = updatedProducts.some(
        (product) => product.lineError && product.lineError.trim() !== ""
      );

      const isComplete = updatedProducts.every(
        (product) => product.checked === "checked"
      );

      setOrderStatus(
        isComplete ? "Completed" : hasError ? "Errors" : "Partially Checked"
      );

      return updatedProducts;
    });
  };

  const setAllProductsToChecked = () => {
    // Update the status of all products to 'checked'
    // console.log(products);
    /* console.log("SetAll");
     */
    setProducts((currentProducts) =>
      currentProducts.map((product) => ({ ...product, checked: "checked" }))
    );
    setOrderStatus("Completed");
    setOrder(...order1, (orderStatus = orderStatus));
  };

  const submitOrder = async (e) => {
    e.preventDefault();
    // console.log(order1);
    //console.log(products);
    order1.orderStatus = orderStatus;
    try {
      const hasError = products.some(
        (product) => product.lineError && product.lineError.trim() !== ""
      );

      const isComplete = products.every(
        (product) => product.checked === "checked"
      );

      setOrderStatus(
        isComplete ? "Completed" : hasError ? "Errors" : "Partially Checked"
      );
      await axios.put(`/delchecker/orders/${id}`, {
        orderData: order1,
        products: products,
      });

      if (user) {
        navigate("/orders");
      } else {
        navigate("/");
      }
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  if (isLoading) {
    return <div>Loading order details...</div>;
  }

  return (
    <div>
      <div className="card-content">
        <h1 className="title">Orders</h1>
        <h2 className="subtitle">Confirm Order</h2>
      </div>
      <div className="card is-shadowless">
        {order1 ? (
          <>
            <div className="card-content">
              <div className="content">
                <p className="has-text-centered">{msg}</p>

                <div className="columns">
                  <div className="column is-half">
                    <div className="field">
                      <label className="label">Advice Note</label>
                      <div className="control">{order1.orderRef}</div>
                    </div>
                    <div className="field">
                      <label className="label">Despatch Date</label>
                      <div className="control">
                        {formatDate(order1.despatchDespatchDate)}
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">Customer Ref</label>
                      <div className="control">{order1.custRef}</div>
                    </div>
                    <div className="field">
                      <label className="label">Postcode</label>
                      <div className="control">{order1.despatchPostcode}</div>
                    </div>
                    <div className="field">
                      <label className="label">Number of Lines</label>
                      <div className="control">{order1.products.length}</div>
                    </div>
                  </div>
                  <div className="column is-half">
                    <div className="field">
                      <label className="label">Order Status</label>
                      <div className="control">{order1.orderStatus}</div>
                    </div>
                    <div className="field">
                      <label className="label">Checked By</label>
                      <div className="control">{order1.despatchName}</div>
                    </div>
                    {/* 
                    <div className="field">
                      <label className="label">Tracking Number</label>
                      <div className="control">5555233435356</div>
                    </div>
                    <div className="field">
                      <label className="label">Tracking Status</label>
                      <div className="control">Delivered - 17/11/2023</div>
                    </div>
                    */}
                  </div>
                </div>
              </div>
            </div>

            <div className="card-content">
              <div className="content">
                {/* Add more order details as needed */}

                <h3>Products:</h3>
                <table>
                  <thead>
                    <tr>
                      <th>Part Number</th>
                      <th>Desciption</th>
                      <th>Quantity</th>
                      <th>
                        Actions
                        {order1.orderStatus !== "Completed" ? (
                          <button onClick={setAllProductsToChecked}>
                            Set All to Checked
                          </button>
                        ) : (
                          ""
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {order1.orderStatus === "Completed"
                      ? products?.map((product) => (
                          <ProductItemComplete
                            key={product.id}
                            product={product}
                          />
                        ))
                      : ""}

                    {order1.orderStatus !== "Completed"
                      ? products?.map((product) => (
                          <ProductItem
                            key={product.id}
                            product={product}
                            //onErrorUpdate={updateProductStatus}
                            onErrorUpdate={handleProductError}
                            onQtyChange={handleProductQtyMissing}
                            onStatusChange={handleProductStatus}
                          />
                        ))
                      : ""}
                  </tbody>
                </table>
                {order1.orderStatus !== "Completed" ? (
                  <div className="field">
                    <div className="control">
                      <button
                        type="submit"
                        onClick={submitOrder}
                        className="button is-success"
                      >
                        Save/Confirm
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
};

export default OpenOrders;
