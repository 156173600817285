import React, { useEffect } from "react";
import Layout from "./LayoutUser";
import OpenOrders from "../components/OpenOrders";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getMe } from "../features/authSlice";

const OrderScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /* useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);
*/
  return (
    <Layout>
      <OpenOrders />
    </Layout>
  );
};

export default OrderScreen;
