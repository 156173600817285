import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
//import XLSX from "xlsx";
import * as XLSX from "xlsx";
if (process.env.NODE_ENV === "production") {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL_PROD;
} else if (process.env.NODE_ENV === "test") {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL_TEST;
} else {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL_DEV;
}

const OrderList = () => {
  const [orders, setOrders] = useState([]);
  const { filter } = useParams();
  const [exportData, setExportData] = useState([]);
  const [status1, setStatus] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getOrders();
  }, [filter]);

  useEffect(() => {
    let vOrders = [];

    [...orders].map((q) => {
      [...q.products].map((p) => {
        let vOrder = {};
        vOrder.id = q.id;
        vOrder.custref = q.custref;
        vOrder.orderRef = q.orderRef;
        vOrder.postcode = q.despatchPostcode;
        vOrder.date = formatDate(q.despatchDespatchDate);
        vOrder.supplier = q.supplier.name;
        vOrder.orderStatus = q.orderStatus;
        vOrder.partCode = p.partCode;
        vOrder.productDesc = p.description;
        vOrder.Qty = p.quantityDespatched;
        vOrder.QtyMissing = p.quantityMissing;
        vOrder.ErrorInformation = p.lineError;
        vOrder.productStatus = p.checked;

        return vOrders.push(vOrder);
      });
    });
    setExportData(vOrders);
  }, [orders]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const exportXLS = () => {
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Temporary file to store Excel
    const filePath = "data.xlsx";
    XLSX.writeFile(workbook, filePath);
  };

  const query = useQuery();
  const startDate = query.get("startDate");
  const endDate = query.get("endDate");
  const status = query.get("status");

  const getOrders = async () => {
    setIsLoading(true);
    const response = await axios.get(
      status
        ? `/delchecker/ordersproducts?status=${status}`
        : `/delchecker/ordersproducts`
    ); //, {
    //  params: { orderStatus: "Errors" },
    // });

    setOrders(response.data);
    setIsLoading(false);
    // console.log(orders);
  };

  const formatDate = (dateString) => {
    return moment(dateString).format("DD/MM/YYYY");
  };
  /*const deleteOrder = async (orderId) => {
    await axios.delete(`delchecker/orders/${orderId}`);
    getOrders();
  };*/

  /* const downloadExcel = async () => {
    const response = await axios.post("delchecker/export", {
      responseType: "blob", // Important
    });

    // Create a Blob from the PDF Stream
    const file = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Build a URL from the file
    const fileURL = URL.createObjectURL(file);

    // Open the URL on new Window
    window.open(fileURL);
  };*/
  if (isLoading) {
    return <div>Loading order details...</div>;
  }

  return (
    <div>
      <h1 className="title">Orders</h1>
      <h2 className="subtitle">List of Orders</h2>
      {
        <button onClick={exportXLS} className="button is-primary mb-2">
          Export
        </button>
      }
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th>Despatch Number</th>
            <th>Customer Ref</th>
            <th>Supplier Name</th>
            <th>Delivery Postcode</th>
            <th>Despatch Date</th>
            <th>Items</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {orders.length === 0 ? (
            <tr>
              <td colSpan="4">No orders found.</td>
            </tr>
          ) : (
            orders.map((order, index) => (
              <tr key={order.id}>
                <td>{index + 1}</td>
                <td>{order.orderRef}</td>
                <td>{order.custref}</td>
                <td>{order.supplier.name}</td>
                <td>{order.despatchPostcode}</td>
                <td>{formatDate(order.despatchDespatchDate)}</td>
                <td>{order.products.length}</td>
                <td>{order.orderStatus}</td>
                <td>
                  <Link
                    to={`/order/${order.id}`}
                    className="button is-small is-info"
                  >
                    Complete
                  </Link>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default OrderList;
