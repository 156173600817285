import React, { useState, useEffect } from "react";
import axios from "axios";
import error from "../error.png";
import good from "../good.png";
import check from "../check.png";

const ProductItem = ({
  product, //onUpdate
  onStatusChange,
  onQtyChange,
  onErrorUpdate,
}) => {
  const [checked, setStatus] = useState("unchecked"); // 'unchecked', 'checked', 'error'
  const [lineError, setErrorType] = useState("");

  const [quantityMissing, setIssueQuantity] = useState(0);

  /*useEffect(() => {
    console.log(product); // This will log the updated value
  }, [product]);*/

  const handleProductStatusChange = (e) => {
    e.preventDefault();
    const newStatus =
      product.checked === "unchecked"
        ? "checked"
        : product.checked === "checked"
        ? "error"
        : "unchecked";
    setStatus(newStatus);
    onStatusChange(product.id, newStatus);

    //onUpdate(product.id, newStatus, lineError, quantityMissing);
  };

  const handleQtyChange = (e) => {
    e.preventDefault();
    let qty = parseInt(e.target.value, 10) || 0;
    //setIssueQuantity(qty);
    if (qty > product.quantityDespatched && qty > 0) {
      qty = product.quantityDespatched;
      //onAddNewLine(product, qty); // Add a new line if issue quantity doesn't match dispatched quantity
    }

    if (product.checked === "error") {
      onQtyChange(product.id, qty);
    }
  };
  const handleLineErrorChange = (e) => {
    e.preventDefault();
    setErrorType(e.target.value);
    if (product.checked === "error") {
      onErrorUpdate(product.id, e.target.value);
    }
  };
  /* OLD HANDLE STATUS
  const handleStatusChange = (e) => {
    e.preventDefault();
    const newStatus = product.checked === 'unchecked' ? 'checked' 
                  : product.checked === 'checked' ? 'error' 
                  : 'unchecked';

    setStatus(newStatus);
    if (newStatus !== 'error') {
      setIssueQuantity(0); // Reset issue quantity when status is not 'error'
      setErrorType(''); // Reset error type
    }

  
    onUpdate(product.id, newStatus, lineError, quantityMissing);
  };*/
  /*
  const handleIssueQuantityChange = (e) => {
    e.preventDefault();
    const qty = parseInt(e.target.value, 10) || 0;
    setIssueQuantity(qty);
    if (qty !== product.quantityDispatched && qty > 0) {
      //onAddNewLine(product, qty); // Add a new line if issue quantity doesn't match dispatched quantity
    }
    if (checked === 'error') {
      onUpdate(product.id, checked, lineError, qty);
    }
  };

  const handleErrorTypeChange = (e) => {
    e.preventDefault();
    setErrorType(e.target.value);
    if (product.checked === 'error') {
      onUpdate(product.id, checked, e.target.value, quantityMissing);
    }
  };*/

  return (
    <>
      <tr>
        <td>{product.partCode}</td>
        <td>{product.description}</td>
        <td>{product.quantityDespatched}</td>
        <td>
          <button
            className="button is-medium"
            onClick={handleProductStatusChange}
          >
            <img
              src={
                product.checked === "unchecked" || product.checked === "0"
                  ? check
                  : product.checked === "checked"
                  ? good
                  : error
              }
              height="40"
              width="40"
              alt="car"
            />
          </button>

          {/*  <button className="button is-medium" onClick=handleStatusChange>
    <span className="icon is-medium">
    product.checked === 'unchecked' || product.checked === '0'? '🔲' : product.checked === 'checked' ? '✅' : '❗'
    </span>
  </button>*/}
        </td>
      </tr>
      {product.checked === "error" && (
        <>
          <tr>
            <td colSpan={4}>
              <div className="field is-horizontal">
                <div className="select">
                  <select
                    onChange={handleLineErrorChange}
                    value={product.lineError}
                  >
                    <option value="">Select Issue</option>
                    <option value="damaged">Damaged</option>
                    <option value="missing">Missing</option>
                    <option value="wrongItem">Wrong Item</option>
                  </select>
                </div>
                <div className="field-label">
                  <label className="label">
                    How many are {product.lineError}?
                  </label>
                </div>
                <input
                  className="input"
                  type="text"
                  min="0"
                  max={product.quantityDispatched}
                  value={product.quantityMissing}
                  onChange={handleQtyChange}
                />
              </div>
            </td>
          </tr>
        </>
      )}
    </>
  );
};

export default ProductItem;
